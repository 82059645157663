import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BreadcrumbItem, BreadcrumbsBar, Wizard } from '@anatoscope/circlestorybook';
import IdentificationForm from './create-product-forms/IdentificationForm';
import ComponentsForm from './create-product-forms/ComponentsForm';
import ConfigurationForm from './create-product-forms/ConfigurationForm';
import CompatibilityForm from './create-product-forms/CompatibilityForm';
import ProductRuleForm from './create-product-forms/ProductRuleForm';
import Summary from './create-product-forms/Summary';
import { productsActions } from '../../../../../store/products/products.reducers';
import { useAppDispatch } from '../../../../../store/hooks';
import { WizardOption } from '../../../../../models/wizard';
import styles from '../create-product-page/create-product-page.module.scss';
import { fetchAllActiveComponents } from '../../../../../store/components/components.reducers';
import { feedbackActions } from '../../../../../store/feedback/feedback.reducer';
import { getMessageError } from '../../../../../utils/utils';
import { fetchActiveCircleCadVersions } from '../../../../../store/circle-cad-versions/circle-cad-versions.reducers';
import { ToastType } from '../../../../../enum/feedback';

export type CreateProductNavProps = {
  nextStepCallback?: () => void;
  previousStepCallback?: () => void;
  submitCallback?: () => void;
};

const CreateProductPage = () => {
  const { t } = useTranslation('catalog');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const wizardOptions: WizardOption[] = [
    {
      id: 1,
      title: t('products.productForm.wizardSteps.id')
    },
    {
      id: 2,
      title: t('products.productForm.wizardSteps.components')
    },
    {
      id: 3,
      title: t('products.productForm.wizardSteps.conf')
    },
    {
      id: 4,
      title: t('products.productForm.wizardSteps.rules')
    },
    {
      id: 5,
      title: t('products.productForm.wizardSteps.compatibility')
    },
    {
      id: 6,
      title: t('products.productForm.wizardSteps.summary')
    }
  ];

  useEffect(() => {
    dispatch(fetchAllActiveComponents())
      .unwrap()
      .catch((error) =>
        dispatch(
          feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
        )
      );
    dispatch(fetchActiveCircleCadVersions());
  }, []);

  const navigateNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const navigatePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = () => {
    navigate('/catalog/products');
    dispatch(
      feedbackActions.setToast({
        message: t('products.productForm.toast.success'),
        type: ToastType.SUCCESS
      })
    );
    dispatch(productsActions.resetProduct());
  };

  return (
    <div className={styles['create-product-page']}>
      <BreadcrumbsBar type={'navigation'}>
        <BreadcrumbItem
          link="/catalog/products"
          text={t('products.productForm.breadcrumb.products')}
        />
        <BreadcrumbItem
          link="/catalog/products/create"
          text={t('products.productForm.breadcrumb.create')}
        />
      </BreadcrumbsBar>
      <section className={styles['create-product-page__main']}>
        <Wizard
          options={wizardOptions}
          selectedIndex={currentStep}
          className={styles['create-product-page__wizard']}
        />

        {currentStep === 0 && <IdentificationForm nextStepCallback={navigateNextStep} />}
        {currentStep === 1 && (
          <ComponentsForm
            nextStepCallback={navigateNextStep}
            previousStepCallback={navigatePreviousStep}
          />
        )}
        {currentStep === 2 && (
          <ConfigurationForm
            nextStepCallback={navigateNextStep}
            previousStepCallback={navigatePreviousStep}
          />
        )}
        {currentStep === 3 && (
          <ProductRuleForm
            nextStepCallback={navigateNextStep}
            previousStepCallback={navigatePreviousStep}
          />
        )}
        {currentStep === 4 && (
          <CompatibilityForm
            nextStepCallback={navigateNextStep}
            previousStepCallback={navigatePreviousStep}
          />
        )}
        {currentStep === 5 && (
          <Summary previousStepCallback={navigatePreviousStep} submitCallback={handleSubmit} />
        )}
      </section>
    </div>
  );
};

export default CreateProductPage;
