import { useEffect, useState } from 'react';
import { Box, Button, Dropdown, Text } from '@anatoscope/circlestorybook';
import { Address, Establishment } from '../../../../../models/establishment';
import { AdminRole, Country } from '../../../../../enum/user';
import { UserInfos } from '../../../../../models/user';
import i18next from 'i18next';
import { usersActions } from '../../../../../store/users/users.reducers';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { userSelector } from '../../../../../store/users/users.selectors';
import {
  clinicListForDropdownSelector,
  clinicListSelector,
  designCenterListForDropdownSelector,
  designCenterListSelector,
  isClinicsLoadingSelector,
  isDesignCentersLoadingSelector,
  isLaboratoriesLoadingSelector,
  laboratoryListForDropdownSelector,
  laboratoryListSelector
} from '../../../../../store/establishment/establishment.selectors';
import {
  fetchClinicsAsync,
  fetchDesignCentersAsync,
  fetchLaboratoriesAsync
} from '../../../../../store/establishment/establishment.reducer';
import { feedbackActions } from '../../../../../store/feedback/feedback.reducer';
import {
  getMessageError,
  isClinic,
  isDentistRole,
  isDesignCenter,
  isDesignerRole,
  isLaboratory
} from '../../../../../utils/utils';
import styles from './user-forms.module.scss';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import { ToastType } from '../../../../../enum/feedback';

type Props = {
  establishment: Establishment;
  establishmentApiKeyBack: string;
  saveCallback: (user: Partial<UserInfos>, email: string) => void;
  closeSideNavCallback: (opened: boolean) => void;
};

const EstablishmentForm = ({
  establishment,
  establishmentApiKeyBack,
  saveCallback,
  closeSideNavCallback
}: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const clinicList = useAppSelector(clinicListSelector);
  const clinicListForDropdown = useAppSelector(clinicListForDropdownSelector);
  const designCenterList = useAppSelector(designCenterListSelector);
  const designCenterListForDropdown = useAppSelector(designCenterListForDropdownSelector);
  const laboratoryList = useAppSelector(laboratoryListSelector);
  const laboratoryListForDropdown = useAppSelector(laboratoryListForDropdownSelector);
  const isLaboratoriesLoading = useAppSelector(isLaboratoriesLoadingSelector);
  const isClinicsLoadingLoading = useAppSelector(isClinicsLoadingSelector);
  const isDesignCentersLoading = useAppSelector(isDesignCentersLoadingSelector);

  const [name, setName] = useState<string>(establishment?.name || '');
  const [address, setAddress] = useState<string>(establishment?.address?.address || '');
  const [additionalAddress, setAdditionalAddress] = useState<string>(
    establishment?.address?.additionalAddress || ''
  );
  const [zipCode, setZipCode] = useState<string>(establishment?.address?.zipCode || '');
  const [city, setCity] = useState<string>(establishment?.address?.city || '');
  const [country, setCountry] = useState<Country | undefined>(establishment?.address?.country);
  const [region, setRegion] = useState<string>(establishment?.address?.region || '');
  const [selectedClinicId, setSelectedClinicId] = useState<number | undefined>(undefined);
  const [selectedDesignCenterId, setSelectedDesignCenterId] = useState<number | undefined>();
  const [selectedLaboratoryId, setSelectedLaboratoryId] = useState<number | undefined>(undefined);
  const [isEstablishmentMandatory, setIsEstablishmentMandatory] = useState(false);

  const clinicMandatory = i18next.t('userForm.clinicMandatory', { ns: 'user' });
  const designCenterMandatory = i18next.t('userForm.designCenterMandatory', { ns: 'user' });
  const laboratoryMandatory = i18next.t('userForm.laboratoryMandatory', { ns: 'user' });

  const isDisplayDentistFields = user?.role ? isDentistRole(user.role) : false;
  const isDisplayDesignCenterFields = user?.role ? isDesignerRole(user.role) : false;

  useEffect(() => {
    if (establishment?.type && isLaboratory(establishment.type) && !laboratoryList.length) {
      dispatch(fetchLaboratoriesAsync())
        .unwrap()
        .catch((error) =>
          dispatch(
            feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
          )
        );
    }
    if (establishment?.type && isClinic(establishment.type) && !clinicList.length) {
      dispatch(fetchClinicsAsync())
        .unwrap()
        .catch((error) =>
          dispatch(
            feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
          )
        );
    }
    if (establishment?.type && isDesignCenter(establishment.type) && !designCenterList.length) {
      dispatch(fetchDesignCentersAsync())
        .unwrap()
        .catch((error) =>
          dispatch(
            feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
          )
        );
    }

    if (isDisplayDentistFields && user?.clinic && clinicList.length) {
      setSelectedClinicId(establishment.id);
    }
    if (isDisplayDesignCenterFields && user?.designCenter && designCenterList.length) {
      setSelectedDesignCenterId(establishment.id);
    }
    if (user?.laboratory && laboratoryList.length) {
      setSelectedLaboratoryId(establishment.id);
    }
  }, [clinicList, designCenterList, laboratoryList]);

  const onSelectDesignCenter = (id: number) => {
    setSelectedDesignCenterId(id || undefined);
    updateEstablishment(designCenterList.find((ets) => ets.id === id));
    setIsEstablishmentMandatory(!id);
  };

  const onSelectClinic = (id: number): void => {
    setSelectedClinicId(id || undefined);
    updateEstablishment(clinicList.find((ets) => ets.id === id));
    setIsEstablishmentMandatory(!id);
  };

  const onSelectLaboratory = (id: number): void => {
    setSelectedLaboratoryId(id || undefined);
    updateEstablishment(laboratoryList.find((ets) => ets.id === id));
    setIsEstablishmentMandatory(!id);
  };

  const updateEstablishment = (establishment: Establishment | undefined): void => {
    setName(establishment?.name || '');
    setAddress(establishment?.address?.address || '');
    setAdditionalAddress(establishment?.address?.additionalAddress || '');
    setZipCode(establishment?.address?.zipCode || '');
    setCity(establishment?.address?.city || '');
    setRegion(establishment?.address?.region || '');
    setAddress(establishment?.address?.address || '');
    setCountry(establishment?.address?.country || undefined);
  };

  const establishmentId = (): number | undefined => {
    if (establishment?.type && isClinic(establishment.type)) return selectedClinicId;
    if (establishment?.type && isLaboratory(establishment.type)) return selectedLaboratoryId;
    if (establishment?.type && isDesignCenter(establishment.type)) return selectedDesignCenterId;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (!establishmentId()) {
      setIsEstablishmentMandatory(true);
      return;
    }

    establishment = {
      ...establishment,
      id: establishmentId(),
      name: name,
      address: address
        ? ({
            address: address || undefined,
            additionalAddress: additionalAddress || undefined,
            zipCode: zipCode || undefined,
            city: city || undefined,
            country: country || undefined,
            region: region || undefined
          } as Address)
        : undefined
    };

    const userData: UserInfos = {
      ...user,
      [establishmentApiKeyBack]: establishment
    };
    dispatch(usersActions.setUser(userData));

    const paramsToUpdate =
      user?.role &&
      establishment?.type &&
      isDentistRole(user.role) &&
      isLaboratory(establishment.type)
        ? {
            [establishmentApiKeyBack]: {
              id: establishment.id
            }
          }
        : {
            [establishmentApiKeyBack]: {
              id: establishment.id
            }
          };
    if (user?.email) saveCallback(paramsToUpdate, user.email);
    closeSideNavCallback(false);
  };

  return (
    <Box color={ColorPropsEnum.WHITE}>
      <form onSubmit={handleSubmit}>
        {user?.role !== AdminRole.ADMIN ? (
          <>
            {establishment?.type && isLaboratory(establishment.type) && (
              <Dropdown
                data={isLaboratoriesLoading ? [] : [laboratoryListForDropdown]}
                value={selectedLaboratoryId}
                placeholder={i18next.t('userForm.choose', { ns: 'user' })}
                onChange={(newValue: number) => onSelectLaboratory(+newValue)}
                helperText={isEstablishmentMandatory ? laboratoryMandatory : undefined}
                variant={isEstablishmentMandatory ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
                className={styles['user-forms__input']}
              />
            )}
            {establishment?.type && isDesignCenter(establishment.type) && (
              <Dropdown
                data={isDesignCentersLoading ? [] : [designCenterListForDropdown]}
                value={selectedDesignCenterId}
                placeholder={i18next.t('userForm.choose', { ns: 'user' })}
                onChange={(newValue: number) => onSelectDesignCenter(+newValue)}
                helperText={isEstablishmentMandatory ? designCenterMandatory : undefined}
                variant={isEstablishmentMandatory ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
                className={styles['user-forms__input']}
              />
            )}
            {establishment?.type && isClinic(establishment.type) && (
              <Dropdown
                data={isClinicsLoadingLoading ? [] : [clinicListForDropdown]}
                value={selectedClinicId}
                placeholder={i18next.t('userForm.choose', { ns: 'user' })}
                onChange={(newValue: number) => onSelectClinic(+newValue)}
                helperText={isEstablishmentMandatory ? clinicMandatory : undefined}
                variant={isEstablishmentMandatory ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
                className={styles['user-forms__input']}
              />
            )}
            {name && (
              <div className={styles['user-forms__input']}>
                <Text label={name}></Text>
                {address && (
                  <>
                    <Text label={address}></Text>
                    {additionalAddress && <Text label={additionalAddress}></Text>}

                    <Text label={zipCode + ' ' + city + ' ' + (region || '')}></Text>
                    <Text
                      label={
                        country && Object.values(Country).includes(country)
                          ? i18next.t(`countries.${country.toLowerCase()}`, {
                              ns: 'common'
                            })
                          : ''
                      }></Text>
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <Text label={i18next.t('establishmentModal.theEnd', { ns: 'user' })} />
        )}

        <div className="form__submit-button">
          <Button label={i18next.t('action.update', { ns: 'common' })} type="submit" />
        </div>
      </form>
    </Box>
  );
};

export default EstablishmentForm;
