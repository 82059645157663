import styles from './list-item.module.scss';
import { IconButton, Text, Link } from '@anatoscope/circlestorybook';
import { ColorPropsEnum } from '../../../enum/color.enum';
import { ListItemProps } from './list-item.utils.ts';

const ListItem = ({
  headlineHref,
  headline,
  supportingText,
  actions,
  bgColor,
  itemKey
}: ListItemProps) => {
  const itemClasses = [styles['list-item'], styles[`list-item--${bgColor}`]];

  return (
    <li className={itemClasses.join(' ')}>
      <div className={styles['list-item__content--start']}>
        {headlineHref ? (
          <Link label={headline} href={headlineHref} />
        ) : (
          <Text className={styles['list-item__headline']} label={headline} type="title" size="xs" />
        )}
        <Text
          className={styles['list-item__supporting-text']}
          label={supportingText}
          type="text"
          color={ColorPropsEnum.GREY}
          size="s"
        />
      </div>
      <div className={styles['list-item__content--end']}>
        {actions?.map((action) => (
          <IconButton
            key={`${itemKey}_${action.faIconClass}`}
            faIconClass={action.faIconClass}
            color={action.color}
            onClick={action.onClick}
            iconSize="sm"
            radius="full"
          />
        ))}
      </div>
    </li>
  );
};

export default ListItem;
