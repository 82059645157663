import axios, { AxiosResponse } from 'axios';
import { UserInfos } from '../models/user';

export const getUsers = (
  page: number,
  limit: number,
  filters: string,
  sort: string
): Promise<AxiosResponse> => {
  return axios.get(`/users?page=${page}&limit=${limit}${filters}${sort}`);
};

export const getUserByEmail = (email: string): Promise<AxiosResponse> => {
  return axios.get(`/users/${email}`);
};

export const createUser = (user: UserInfos): Promise<AxiosResponse> => {
  const url = `/users`;
  return axios.post(url, user);
};

export const patchUser = (user: Partial<UserInfos>, email: string): Promise<AxiosResponse> => {
  const url = `/users/${email}`;
  return axios.patch(url, user);
};

export const deactivateUser = (user: UserInfos): Promise<AxiosResponse> => {
  const url = `/users/${user.email}`;
  return axios.delete(url);
};

export const resetPasswordUser = (user: UserInfos): Promise<AxiosResponse> => {
  const url = `/users/${user.email}/reset-password`;
  return axios.post(url);
};
