import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { useParams } from 'react-router-dom';
import {
  BreadcrumbItem,
  BreadcrumbsBar,
  BubblePicto,
  Button,
  Chips,
  Divider,
  Skeleton,
  Text
} from '@anatoscope/circlestorybook';

import styles from './product-details-page.module.scss';
import {
  activeProductsSelector,
  areActiveProductsLoadingSelector,
  isProductLoadingSelector,
  productDetailsSelector
} from '../../../../../store/products/products.selectors';
import {
  fetchAllActiveProducts,
  fetchProductByIdAsync
} from '../../../../../store/products/products.reducers';
import { useTranslation } from 'react-i18next';
import { feedbackActions } from '../../../../../store/feedback/feedback.reducer';
import { formatDate, getMessageError } from '../../../../../utils/utils';
import { getLocalizedProperty } from '../../catalog';
import { Product, ProductWithCompatibility } from '../../../../../models/product';
import i18next from 'i18next';
import { getComponentListItemSupportingText } from '../product';
import ListFeature, { ListFeatureProps } from '../../../../../features/list/ListFeature';
import { ComponentInProduct } from '../../../../../models/component';
import DatagridFeature from '../../../../../features/datagrid/DatagridFeature';
import {
  colCheckBox,
  colFamily,
  colProductLabel,
  computeDetailDatagridDatasource
} from '../create-product-page/create-product-forms/compatibilities';
import { commonTypesSelector } from '../../../../../store/common-types/common-types.selectors';
import { InjectionModeEnum } from '../../../../../enum/product.enum';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import { ToastType } from '../../../../../enum/feedback';
import { getColumnOptions } from '../../../../../features/datagrid/datagrid.utils.ts';
import { ItemTypeBgColorEnum } from '../../../../../features/list/list-item/list-item.utils.ts';

const ProductDetailsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation(['catalog']);
  const productDetails = useAppSelector(productDetailsSelector);
  const isProductLoading = useAppSelector(isProductLoadingSelector);
  const activeProducts = useAppSelector(activeProductsSelector);
  const areActiveProductsLoading = useAppSelector(areActiveProductsLoadingSelector);
  const commonTypes = useAppSelector(commonTypesSelector);
  const [compatibleProducts, setCompatibleProducts] = useState<ProductWithCompatibility[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchProductByIdAsync(id))
        .unwrap()
        .catch((error) => {
          dispatch(
            feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
          );
        });
    }

    dispatch(fetchAllActiveProducts())
      .unwrap()
      .catch((error) => {
        dispatch(
          feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
        );
      });
  }, []);

  useEffect(() => {
    setCompatibleProducts(computeDetailDatagridDatasource(productDetails, activeProducts));
  }, [productDetails, activeProducts]);

  const buildHeader = (title: string | undefined, buttons: string[], withIcon = false) => {
    return (
      <header className={styles['product-details-page__content__section__header']}>
        {productDetails && withIcon && (
          <BubblePicto
            className={styles['product-details-page__content__section__bubblePicto']}
            size="small"
            displaysBorder={true}
            color={
              ColorPropsEnum[
                `FAMILY_${productDetails.family.toUpperCase()}` as keyof typeof ColorPropsEnum
              ]
            }
            url={productDetails.imageUrl}
            data-cy="bubble-picto-product-details"
          />
        )}
        {title ? (
          <Text
            className={
              styles[
                withIcon
                  ? 'product-details-page__content__section__title--with-icon'
                  : 'product-details-page__content__section__title--without-icon'
              ]
            }
            label={title}
            size="s"
            type="title"></Text>
        ) : (
          <Skeleton type="title" />
        )}
        <div className={styles['product-details-page__content__section__actions']}>
          {isProductLoading && <Skeleton type="title" />}
          {buttons.includes('disable') && productDetails && !productDetails.deletedAt && (
            <Button
              className={styles['product-details-page__content__section__actions__deactivation']}
              label={t('action.deactivate', { ns: 'common' })}
              category="secondary"
              isDisabled={true}
              size={'s'}></Button>
          )}
          {buttons.includes('edit') && productDetails && (
            <Button
              iconRight="fa-pen-to-square"
              label={t('action.edit', { ns: 'common' })}
              category="secondary"
              isDisabled={true}
              size={'s'}></Button>
          )}
        </div>
      </header>
    );
  };

  const buildTextItem = (title: string, value: string | undefined) => {
    return (
      <div className={styles['product-details-page__content__section__content__item']}>
        <Text label={title} color={ColorPropsEnum.GREY}></Text>
        {isProductLoading && <Skeleton type="text" />}
        {productDetails && <Text label={value}></Text>}
      </div>
    );
  };

  const buildComponentList = () => {
    const listFeatureProps: ListFeatureProps = {
      items: [],
      className: styles['product-details-page__content__section__list']
    };
    productDetails?.components?.forEach((component) => {
      listFeatureProps.items.push({
        itemKey: component.id?.toString() as string,
        headlineHref: `/catalog/components/${component.id}/detail`,
        headline: component[getLocalizedProperty('name') as keyof ComponentInProduct] as string,
        supportingText: getComponentListItemSupportingText(component),
        bgColor: ItemTypeBgColorEnum.GREY
      });
    });

    return <ListFeature {...listFeatureProps} />;
  };

  const computeDatagridHeight = (dataLength?: number): { minHeight: number } | undefined => {
    if (dataLength) {
      const headerHeight = 41;
      const rowHeight = 40;
      // We add half a row to make it obvious it's scrollable
      const maxDatagridHeight = headerHeight + rowHeight * 10.5;
      let computedHeight = headerHeight + dataLength * rowHeight;
      computedHeight = computedHeight < maxDatagridHeight ? computedHeight : maxDatagridHeight;
      return computedHeight ? { minHeight: computedHeight } : undefined;
    }
    return undefined;
  };

  return (
    <div>
      <BreadcrumbsBar>
        <BreadcrumbItem
          text={t('products.productForm.breadcrumb.products')}
          link="/catalog/products"></BreadcrumbItem>
        <BreadcrumbItem
          text={t('products.productForm.breadcrumb.details')}
          link={`/catalog/products/${id}/detail`}></BreadcrumbItem>
      </BreadcrumbsBar>
      <div className={styles['product-details-page__content']}>
        <section className={styles['product-details-page__content__section']}>
          {buildHeader(
            productDetails
              ? (productDetails[getLocalizedProperty('name') as keyof Product] as string)
              : undefined,
            ['edit', 'disable'],
            true
          )}
          <div className={styles['product-details-page__content__section__content']}>
            <div className={styles['product-details-page__content__section__content__item']}>
              <Text
                label={t('products.productForm.status.label')}
                color={ColorPropsEnum.GREY}></Text>
              {isProductLoading && <Skeleton type="text" />}
              {productDetails && (
                <Chips
                  firstLabel={i18next.t(productDetails.deletedAt ? 'inactive' : 'active', {
                    ns: 'common'
                  })}
                  secondLabel={
                    productDetails.deletedAt ? formatDate(new Date(productDetails.deletedAt)) : ''
                  }
                  color={productDetails.deletedAt ? ColorPropsEnum.DANGER : ColorPropsEnum.SUCCESS}
                  size="m"></Chips>
              )}
            </div>
            {buildTextItem(t('products.productForm.labelFr.label'), productDetails?.labelFr)}
            {buildTextItem(t('products.productForm.labelEn.label'), productDetails?.labelEn)}
            <div className={styles['product-details-page__content__section__content__item']}>
              <Text
                label={t('products.productForm.family.label')}
                color={ColorPropsEnum.GREY}></Text>
              {isProductLoading && <Skeleton type="text" />}
              {productDetails && (
                <Chips
                  firstLabel={t(`products.families.${productDetails.family}`)}
                  secondLabel={
                    productDetails.class ? t(`products.classes.${productDetails.class}`) : undefined
                  }
                  color={
                    productDetails.family
                      ? ColorPropsEnum[
                          `FAMILY_${productDetails.family.toUpperCase()}` as keyof typeof ColorPropsEnum
                        ]
                      : ColorPropsEnum.PRIMARY
                  }
                  size="m"></Chips>
              )}
            </div>
            {productDetails?.category &&
              buildTextItem(
                t('products.productForm.category.summaryLabel'),
                t(`products.categories.${productDetails.category}`)
              )}
            {productDetails?.subCategories && productDetails.subCategories.length > 0 && (
              <div
                className={styles['product-details-page__content__section__content__item']}
                data-cy="product_details__sub-categories">
                <Text
                  label={t('products.productForm.subCategories.summaryLabel')}
                  color={ColorPropsEnum.GREY}></Text>
                {isProductLoading && <Skeleton type="text" />}
                {productDetails?.subCategories?.map((subCategory) => (
                  <Text key={subCategory} label={t(`products.subCategories.${subCategory}`)}></Text>
                ))}
              </div>
            )}
          </div>
        </section>
        <Divider />
        <section className={styles['product-details-page__content__section']}>
          {buildHeader(t('products.productForm.wizardSteps.components'), ['edit'])}
          <div className={styles['product-details-page__content__section__content']}>
            {productDetails && buildComponentList()}
          </div>
        </section>
        <Divider />
        <section className={styles['product-details-page__content__section']}>
          {buildHeader(t('products.productForm.wizardSteps.conf'), ['edit'])}
          <div className={styles['product-details-page__content__section__content']}>
            {buildTextItem(
              t('products.productForm.circleCadVersion.detailLabel'),
              productDetails?.circleCadVersion ?? t('products.productForm.circleCadVersion.noData')
            )}
            {productDetails?.circleCadProductType &&
              buildTextItem(
                t('products.productForm.circleCadProductType.label'),
                productDetails.circleCadProductType
              )}
            {productDetails?.isChairSide &&
              buildTextItem(
                t('products.productForm.isChairSide.summaryTitle'),
                t('products.productForm.isChairSide.compatible')
              )}
            {productDetails &&
              !productDetails?.isChairSide &&
              buildTextItem(
                t('products.productForm.isChairSide.summaryTitle'),
                t('products.productForm.isChairSide.incompatible')
              )}
            {productDetails &&
              buildTextItem(
                t('products.productForm.teethMode.label'),
                t(`teethModes.${productDetails.teethMode}`)
              )}
            {productDetails &&
              buildTextItem(
                t('products.productForm.stumpMode.label'),
                t(`stumpModes.${productDetails.stumpMode}`)
              )}
            {productDetails &&
              productDetails.injectionMode === InjectionModeEnum.SELECTABLE &&
              buildTextItem(t('injectionModes'), t('yes', { ns: 'common' }))}
            {productDetails &&
              buildTextItem(
                t('products.productForm.proportionalBilling.title'),
                productDetails.proportionalBilling
                  ? t('products.productForm.proportionalBilling.label')
                  : t('products.productForm.proportionalBilling.productBilling')
              )}
          </div>
        </section>
        <Divider />
        <section className={styles['product-details-page__content__section']}>
          {buildHeader(t('products.productForm.wizardSteps.rules'), ['edit'])}
          <div className={styles['product-details-page__content__section__content']}>
            {!productDetails?.productRule?.allowSameProductInOrder &&
              productDetails?.productRule?.allowSaneTeethInArch &&
              !productDetails?.productRule?.allowSameProductOnArch &&
              buildTextItem('', t('products.productForm.summary.noProductRules'))}
            {productDetails?.productRule?.allowSameProductInOrder &&
              buildTextItem('', t('products.productForm.allowSameProductInOrder.label'))}
            {productDetails?.productRule?.allowSameProductOnArch &&
              buildTextItem('', t('products.productForm.allowSameProductOnArch.label'))}
            {!productDetails?.productRule?.allowSaneTeethInArch &&
              buildTextItem('', t('products.productForm.removeSaneTeethInArch.label'))}
          </div>
        </section>
        <Divider />
        <section className={styles['product-details-page__content__section']}>
          {buildHeader(t('products.productForm.wizardSteps.compatibility'), ['edit'])}
          <div className={styles['product-details-page__content__section__content']}>
            {!areActiveProductsLoading && commonTypes && (
              <DatagridFeature
                style={computeDatagridHeight(compatibleProducts.length)}
                columns={[
                  colProductLabel,
                  colFamily(commonTypes),
                  colCheckBox({
                    column: 'toothCompatibility',
                    width: 80
                  }),
                  colCheckBox({
                    column: 'archCompatibility',
                    width: 80
                  }),
                  colCheckBox({
                    column: 'orderCompatibility',
                    width: 110
                  })
                ]}
                dataSource={compatibleProducts}
                livePagination={false}
                pagination={false}
              />
            )}
          </div>
        </section>
        <Divider />
        <section className={styles['product-details-page__content__section']}>
          {buildHeader(t('products.productForm.laboratories.label'), ['edit'])}
          <div className={styles['product-details-page__content__section__content']}>
            <DatagridFeature
              style={computeDatagridHeight((productDetails?.labProducts || []).length)}
              columns={[
                {
                  ...getColumnOptions(
                    'labName',
                    t('products.productForm.labsDatagrid.labName'),
                    1,
                    false,
                    false
                  )
                },
                {
                  ...getColumnOptions(
                    'productionDelay',
                    t('products.productForm.labsDatagrid.productDelay'),
                    1,
                    false,
                    false
                  )
                },
                {
                  ...getColumnOptions(
                    'unitPrice',
                    t('products.productForm.labsDatagrid.unitPrice'),
                    1,
                    false,
                    false
                  )
                }
              ]}
              dataSource={productDetails?.labProducts || []}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProductDetailsPage;
