import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { ComponentCreationForm, ComponentDetails, ComponentsReducer } from '../../models/component';
import { getAllActiveComponents, getComponentById } from '../../services/components.services';

const initialComponents: ComponentsReducer = {
  isComponentLoading: false,
  activeComponentAreLoading: false,
  activeComponents: undefined,
  components: undefined,
  componentDetails: undefined,
  component: {
    componentType: '',
    labelFr: '',
    labelEn: '',
    materials: undefined,
    shades: undefined,
    shapes: undefined,
    aspects: undefined,
    structures: undefined,
    brands: undefined,
    angulations: undefined
  }
};

export const fetchComponentByIdAsync = createAsyncThunk(
  'components/loadComponentById',
  async (id: string, thunkAPI) => {
    try {
      return await getComponentById(id);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAllActiveComponents = createAsyncThunk('components/active', async () => {
  return getAllActiveComponents();
});

export const componentsSlice = createSlice({
  name: 'components',
  initialState: initialComponents,
  reducers: {
    setComponent: (state, action: PayloadAction<ComponentCreationForm | undefined>) => {
      state.component = action.payload;
    },
    resetComponent: (state) => {
      state.component = initialComponents.component;
    },
    setComponentDetails: (state, action: PayloadAction<ComponentDetails>) => {
      state.componentDetails = action.payload;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<ComponentsReducer>) => {
    builder
      .addCase(fetchComponentByIdAsync.pending, (state) => {
        state.isComponentLoading = true;
      })
      .addCase(fetchComponentByIdAsync.fulfilled, (state, actions) => {
        state.isComponentLoading = false;
        state.componentDetails = actions.payload.data;
      })
      .addCase(fetchComponentByIdAsync.rejected, (state) => {
        state.isComponentLoading = false;
      })
      .addCase(fetchAllActiveComponents.pending, (state) => {
        state.activeComponentAreLoading = true;
      })
      .addCase(fetchAllActiveComponents.fulfilled, (state, actions) => {
        state.activeComponentAreLoading = false;
        state.activeComponents = actions.payload.data.data;
      })
      .addCase(fetchAllActiveComponents.rejected, (state) => {
        state.activeComponentAreLoading = false;
      })
      .addCase('RESET_ALL', () => {
        return { ...initialComponents };
      });
  }
});

export const componentsActions = componentsSlice.actions;
