export enum ComponentType {
  FRAME = 'FRAME',
  TOOTH = 'TOOTH',
  GINGIVA = 'GINGIVA',
  PROSTHETIC_STUMP = 'PROSTHETIC_STUMP',
  IMPLANT_ATTACHMENT = 'IMPLANT_ATTACHMENT',
  GUARD = 'GUARD',
  WAX_ON_HARD_BASE = 'WAX_ON_HARD_BASE',
  IMPLANT_SYSTEM = 'IMPLANT_SYSTEM',
  INFRASTRUCTURE = 'INFRASTRUCTURE',
  PARTIAL_TOOTH = 'PARTIAL_TOOTH',
  MODELS = 'MODELS'
}
