import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import {
  areCommonTypesLoadingSelector,
  componentTypeDropdownSelector
} from '../../../../../../../store/common-types/common-types.selectors';
import {
  activeFrameComponentsDropdownSelector,
  activeGingivaComponentsDropdownSelector,
  activeGuardComponentsDropdownSelector,
  activeImplantAttachmentComponentsDropdownSelector,
  activeImplantSystemComponentsDropdownSelector,
  activeInfrastructureComponentsDropdownSelector,
  activeProstheticStumpComponentsDropdownSelector,
  activeToothComponentsDropdownSelector,
  activeWaxOnHardBaseComponentsDropdownSelector,
  activeModelComponentsDropdownSelector,
  areActiveComponentsLoadingSelector,
  activePartialToothComponentsDropdownSelector
} from '../../../../../../../store/components/components.selectors';
import {
  Box,
  Button,
  Dropdown,
  IconButton,
  Link,
  RadioList,
  Text,
  TextField,
  Tooltip,
  TooltipContent
} from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';
import useForm from '../../../../../../../utils/useForm';
import styles from './add-component-form.module.scss';
import { productsActions } from '../../../../../../../store/products/products.reducers';
import { productSelector } from '../../../../../../../store/products/products.selectors';
import { StringObject } from '../../../../../../../models/common';
import {
  ActiveComponentsDropdownItems,
  AddComponentFormData,
  AddComponentFormProps,
  AddComponentQuantityEnum,
  buildComponentTypeHelper,
  computeComponentsFromFormData,
  computeInitialFormData,
  getHasOnlyOneRadioOptions,
  getQuantityRadioOptions
} from './add-component';
import { fetchAllActiveComponents } from '../../../../../../../store/components/components.reducers';
import { ColorPropsEnum } from '../../../../../../../enum/color.enum';
import { ProductCreationForm } from '../../../../../../../models/product.tsx';

const AddComponentForm = ({
  onSubmitCallback,
  component,
  resetComponentTypeToEdit
}: AddComponentFormProps) => {
  const { t } = useTranslation(['catalog']);
  const dispatch = useAppDispatch();
  const areCommonTypesLoading = useAppSelector(areCommonTypesLoadingSelector);
  const componentTypesDropdown = useAppSelector(componentTypeDropdownSelector);
  const product = useAppSelector(productSelector);
  const areActiveComponentsLoading = useAppSelector(areActiveComponentsLoadingSelector);
  const [componentExistsInProduct, setComponentExistsInProduct] = useState(false);
  const [minComponentsNb, maxComponentsNb] = [1, 16];
  const initialData: AddComponentFormData = computeInitialFormData(minComponentsNb, component);
  const activeComponents: ActiveComponentsDropdownItems = {
    FRAME: useAppSelector(activeFrameComponentsDropdownSelector),
    TOOTH: useAppSelector(activeToothComponentsDropdownSelector),
    GINGIVA: useAppSelector(activeGingivaComponentsDropdownSelector),
    INFRASTRUCTURE: useAppSelector(activeInfrastructureComponentsDropdownSelector),
    PROSTHETIC_STUMP: useAppSelector(activeProstheticStumpComponentsDropdownSelector),
    IMPLANT_ATTACHMENT: useAppSelector(activeImplantAttachmentComponentsDropdownSelector),
    WAX_ON_HARD_BASE: useAppSelector(activeWaxOnHardBaseComponentsDropdownSelector),
    IMPLANT_SYSTEM: useAppSelector(activeImplantSystemComponentsDropdownSelector),
    GUARD: useAppSelector(activeGuardComponentsDropdownSelector),
    PARTIAL_TOOTH: useAppSelector(activePartialToothComponentsDropdownSelector),
    MODELS: useAppSelector(activeModelComponentsDropdownSelector)
  };

  // Form configuration
  const submitCallback = () => {
    // Update product in state
    dispatch(
      productsActions.setProduct({
        ...product,
        components: computeComponentsFromFormData(
          values as AddComponentFormData,
          product as ProductCreationForm,
          activeComponents
        )
      } as ProductCreationForm)
    );

    // Reset form & call submit callback from parent form
    resetComponentTypeToEdit();
    onSubmitCallback();
  };

  const validateCallback = () => {
    // In here, we'll override basic error management to make it stick to our needs
    const newErrors: StringObject = {};

    // Make sure a component type & a component have been selected
    ['componentType', 'componentId'].forEach((key) => {
      if (!values[key]) {
        newErrors[key] = 'empty';
      }
    });

    // If product has only one component we need to make sure required options are filled
    // If product has several components, we need to make sure quantity has been provided
    if (values.quantity === AddComponentQuantityEnum.HAS_ONLY_ONE) {
      if (!values.hasOnlyOneOptions) {
        newErrors['options'] = 'has-only-one-empty';
      }
    } else if (values.quantity === AddComponentQuantityEnum.HAS_SEVERAL) {
      if (!values.minimumQuantity) {
        newErrors['minimumQuantity'] = 'empty';
      }

      if ((values.minimumQuantity as number) < minComponentsNb) {
        newErrors['minimumQuantity'] = 'invalid-min';
      }
      if ((values.minimumQuantity as number) > maxComponentsNb) {
        newErrors['minimumQuantity'] = 'invalid-max';
      }
    }

    return newErrors;
  };
  const { values, errors, handleSelect, handleSubmit, handleChange } = useForm(
    initialData,
    submitCallback,
    validateCallback
  );
  const customComponentTypeHandleSelect = (newValue: string) => {
    let component = undefined;
    if (newValue) {
      component = (product?.components || []).find(
        (component) => component.componentType === newValue
      );
    }
    if (component) {
      setComponentExistsInProduct(true);
    } else {
      setComponentExistsInProduct(false);
    }
    handleSelect(newValue, 'componentType');
  };

  const customQuantityHandleSelect = (newValue: AddComponentQuantityEnum) => {
    // Handle the selection of the option
    handleSelect(newValue, 'quantity');

    // Reset to the default option for radio button
    if (newValue === AddComponentQuantityEnum.HAS_ONLY_ONE) {
      handleSelect('isOptional', 'hasOnlyOneOptions');
    }
  };

  // Build component type helper
  const componentTypeHelper: { text: string | undefined; variant: string } =
    buildComponentTypeHelper(errors, componentExistsInProduct);

  // Return form
  return (
    <form onSubmit={handleSubmit} className={styles['add-component-form']} noValidate>
      <Box color={ColorPropsEnum.WHITE}>
        <>
          <Dropdown
            data={areCommonTypesLoading ? [] : [componentTypesDropdown]}
            value={values.componentType}
            isLoading={areCommonTypesLoading}
            label={t('products.productForm.components.form.componentType.label')}
            placeholder={t('form.select', { ns: 'common' })}
            className={styles['add-component-form__dropdown']}
            onChange={(newValue: string) => customComponentTypeHandleSelect(newValue)}
            variant={componentTypeHelper.variant}
            helperText={componentTypeHelper.text}></Dropdown>
          <div className={styles['add-component-form__dropdown--with-icon']}>
            <Dropdown
              data={
                areActiveComponentsLoading || !values.componentType
                  ? []
                  : [
                      activeComponents[
                        values.componentType as keyof ActiveComponentsDropdownItems
                      ] || []
                    ]
              }
              isLoading={areActiveComponentsLoading}
              isDisabled={!values.componentType}
              value={values.componentId}
              label={t('products.productForm.components.form.component.label')}
              placeholder={t('form.select', { ns: 'common' })}
              onChange={(newValue: string) => handleSelect(newValue, 'componentId')}
              variant={errors['componentId'] ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
              className={styles['add-component-form__dropdown--with-icon__dropdown']}
              helperText={
                errors['componentId'] &&
                t(`products.productForm.components.form.component.${errors['componentId']}`)
              }></Dropdown>
            <div className={styles['add-component-form__dropdown--with-icon__icon']}>
              <Tooltip>
                <TooltipContent>
                  {t('products.productForm.components.form.component.tooltip')}
                </TooltipContent>
                <IconButton
                  faIconClass="arrows-rotate"
                  onClick={() => {
                    dispatch(fetchAllActiveComponents());
                  }}
                  radius="full"
                  color={ColorPropsEnum.PRIMARY}
                  isDisabled={areActiveComponentsLoading || !values.componentType}
                />
              </Tooltip>
            </div>
          </div>
          <div className={styles['add-component-form__new-component']}>
            <Text size="xs" label={t('products.productForm.components.footer.label')}></Text>

            <Link
              label={t('products.productForm.components.footer.link')}
              target="_blank"
              size="s"
              isUnderlined={true}
              className={styles['add-component-form__new-component__link']}
              href="/catalog/components/create"></Link>
          </div>
          <div className={styles['add-component-form__block']}>
            <Text
              label={t('products.productForm.components.form.quantity.title')}
              size="s"
              bold={true}
              className={styles['add-component-form__block__title']}
            />
            <RadioList
              options={getQuantityRadioOptions()}
              onClick={(newValue: AddComponentQuantityEnum) => customQuantityHandleSelect(newValue)}
              selectedValue={values.quantity}
              className={styles['add-component-form__radio-list__block']}></RadioList>
          </div>
          <div className={styles['add-component-form__block']}>
            <div className={styles['add-component-form__block__title']}>
              <Text
                label={t('products.productForm.components.form.options.label')}
                size="s"
                bold={true}
                className={styles['add-component-form__block__title__label']}
              />
              {errors['options'] && (
                <Text
                  label={t(`products.productForm.components.form.options.${errors['options']}`)}
                  size="s"
                  className={styles['add-component-form__block__title__label--error']}
                />
              )}
            </div>
            <div
              className={[
                styles['add-component-form__block'],
                styles['add-component-form__block--nested']
              ].join(' ')}>
              {values.quantity === AddComponentQuantityEnum.HAS_ONLY_ONE && (
                <RadioList
                  options={getHasOnlyOneRadioOptions(values as AddComponentFormData)}
                  selectedValue={values.hasOnlyOneOptions}
                  onClick={(newValue: string) => handleSelect(newValue, 'hasOnlyOneOptions')}
                  className={styles['add-component-form__radio-list__block']}
                />
              )}
              {values.quantity === AddComponentQuantityEnum.HAS_SEVERAL && (
                <>
                  <Text
                    label={t('products.productForm.components.form.quantity.several.input')}
                    size="s"></Text>
                  <TextField
                    id="minimumQuantity"
                    name="minimumQuantity"
                    type="number"
                    value={values.minimumQuantity}
                    className={[
                      styles['add-component-form__block__input'],
                      styles['add-component-form__block__input--number']
                    ].join(' ')}
                    min={minComponentsNb}
                    max={maxComponentsNb}
                    variant={
                      errors['minimumQuantity'] ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT
                    }
                    helperText={
                      errors['minimumQuantity'] &&
                      t(
                        `products.productForm.components.form.quantity.several.input-${errors['minimumQuantity']}`
                      )
                    }
                    onChange={handleChange}
                  />
                </>
              )}
            </div>
          </div>
          <div className={styles['add-component-form__form-button']}>
            <Button
              label={t(component ? 'action.update' : 'action.add', { ns: 'common' })}
              type="submit"
            />
          </div>
        </>
      </Box>
    </form>
  );
};

export default AddComponentForm;
