import { getToken, isCurrentUser } from './firebase.services';
import { backOff, BackoffOptions } from 'exponential-backoff';
import {
  BaseQueryApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import { createApi } from '@reduxjs/toolkit/query/react';
import { isManuApi, isOrdersApi, isUsersApi } from '../utils/utils.tsx';

const switchLocalEnv = (args: string | FetchArgs) => {
  const url: string = typeof args === 'string' ? args : args.url;

  const subUrl = url.indexOf('?') > 0 ? url.substring(0, url.indexOf('?')) : url;

  // construct a dynamically generated portion of the url
  let adjustedUrl: string = `${import.meta.env.VITE_API_GCP_GATEWAY}${url}`;

  if (import.meta.env.VITE_MS_ORDERS_LOCAL && isOrdersApi(subUrl)) {
    adjustedUrl = `${import.meta.env.VITE_MS_ORDERS_LOCAL}${url}`;
  } else if (import.meta.env.VITE_MS_USERS_LOCAL && isUsersApi(subUrl)) {
    adjustedUrl = `${import.meta.env.VITE_MS_USERS_LOCAL}${url}`;
  } else if (import.meta.env.VITE_MS_MANU_LOCAL && isManuApi(subUrl)) {
    adjustedUrl = `${import.meta.env.VITE_MS_MANU_LOCAL}${url}`;
  }

  return typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };
};

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_GCP_GATEWAY,
  prepareHeaders: async (headers: Headers) => {
    headers.set('x-api-key', import.meta.env.VITE_API_KEY_GCP_GATEWAY);
    if (isCurrentUser()) {
      const token = await getToken();
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

const exponentialBackOffBaseQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: FetchBaseQueryError
) => {
  const backOffOptions: BackoffOptions = {
    maxDelay: 1000,
    numOfAttempts: 5,
    timeMultiple: 1
  };
  return await backOff(async () => {
    const newArgs = switchLocalEnv(args);
    const result = await baseQuery(newArgs, api, extraOptions);
    if (result.error && 'status' in result.error && result.error.status === 504) {
      throw new Error('504 Gateway Timeout');
    }
    return result;
  }, backOffOptions);
};

export const api = createApi({
  // @ts-expect-error bad type. Incoherence basQuery needs the query and we give a resultQuery. Need to improve later.
  baseQuery: exponentialBackOffBaseQuery,
  tagTypes: ['Orders'],
  endpoints: () => ({})
});
